<template>
  <div>
    <!-- If begin date is not provided, show schedule form -->
    <b-card
      v-if="!begin_date"
      title="Schedule Program"
    >
      <b-row>
        <b-col>
          <b-form-group
            class="flex-grow-1"
            label="Program Begin Date"
            label-for="programStart"
          >
            <flat-pickr
              id="programStart"
              v-model="scheduleBeginDate"
              :config="{ altInput: true, altFormat: 'F j, Y'}"
              class="form-control"
              placeholder="Begin Date"
            />
          </b-form-group>
        </b-col>
        <b-col cols="auto">
          <b-button
            variant="primary"
            @click="updateSchedule"
          >
            Schedule
          </b-button>
        </b-col>
      </b-row>
      <!--      <b-col>-->
      <!--        <b-form-group-->
      <!--          label="Working Hours"-->
      <!--          label-for="workingHours"-->
      <!--        >-->
      <!--          <b-row>-->
      <!--            <b-col>-->
      <!--              <flat-pickr-->
      <!--                id="workingHours1"-->
      <!--                v-model="working_hours[0]"-->
      <!--                :config="{-->
      <!--                  altInput: true,-->
      <!--                  enableTime: true,-->
      <!--                  noCalendar: true,-->
      <!--                  dateFormat: 'H:i',-->
      <!--                  defaultDate: '09:00'-->
      <!--                }"-->
      <!--                class="form-control"-->
      <!--                placeholder="Start"-->
      <!--              />-->
      <!--            </b-col>-->
      <!--            <b-col>-->
      <!--              <flat-pickr-->
      <!--                id="workingHours2"-->
      <!--                v-model="working_hours[1]"-->
      <!--                :config="{-->
      <!--                  altInput: true,-->
      <!--                  enableTime: true,-->
      <!--                  noCalendar: true,-->
      <!--                  dateFormat: 'H:i',-->
      <!--                  minTime: working_hours[0],-->
      <!--                  defaultDate: '20:00'-->
      <!--                }"-->
      <!--                class="form-control"-->
      <!--                placeholder="End"-->
      <!--              />-->
      <!--            </b-col>-->
      <!--          </b-row>-->

      <!--        </b-form-group>-->

      <!--      </b-col>-->
    </b-card>
    <!-- If begin date is provided, show calendar -->
    <b-overlay
      :show="$apollo.loading || mutationLoading"
      rounded="sm"
      spinner-variant="primary"
    >
      <b-card
        v-if="begin_date"
        class="mt-2"
      >
        <b-card-title
          class="lead"
        >
          Schedule Activities
        </b-card-title>
        <calendar
          v-if="begin_date"
          :start-date="begin_date"
          :end-date="end_date"
        />
      </b-card>
    </b-overlay>
  </div>
</template>
<script>
import Calendar from '@/views/incubator-portal/programs/components/calendar/Calendar.vue'
import flatPickr from 'vue-flatpickr-component'
import gql from 'graphql-tag'
import {
  BCard, BCardTitle,
} from 'bootstrap-vue'
// import axiosIns from '@/libs/axios'

export default {
  components: {
    // BButton,
    BCard,
    BCardTitle,
    // BCol,
    // BFormGroup,
    // BRow,

    Calendar,
    flatPickr,
  },
  props: ['begin_date', 'end_date'],
  data() {
    return {
      mutationLoading: false,
      // working_hours: ['09:00', '20:00'],
      scheduleBeginDate: '',
    }
  },
  methods: {
    updateSchedule() {
      this.mutationLoading = true
      if (!this.scheduleBeginDate) {
        this.showAlert('Please select a date to schedule program.', 'warning')
      }
      this.$apollo.mutate({
        // Query
        mutation: gql`mutation {
          update_programs_basicinfo_by_pk(pk_columns: {id: ${this.$route.params.id}}, _set: {begin_date: "${this.scheduleBeginDate}"}) {
            begin_date
          }
        }`,
        update: () => {
          this.mutationLoading = false
          this.showAlert('Program scheduled successfully', 'success')
          this.$emit('update')
        },
      })
    },
  },
}
</script>
