var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.$apollo.loading || _vm.mutationLoading}},[_c('div',{staticClass:"custom-search d-flex justify-content-start"},[_c('b-form-group',{staticClass:"form-inline",attrs:{"label":"Search","label-size":"sm"}},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Search resource","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.knowledgeBaseModal",modifiers:{"knowledgeBaseModal":true}}],attrs:{"variant":"primary"}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" Add Resource ")],1)],1)],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"pagination-options":{
      enabled: true,
      perPage: _vm.pageLength,
    },"rows":_vm.rows,"search-options":{
      enabled: true,
      externalQuery: _vm.searchTerm,
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'file')?_c('span',{staticClass:"d-flex flex-nowrap"},[(props.row.is_internal === true)?_c('Promised',{attrs:{"promise":_vm.getLink(props.row.file)},scopedSlots:_vm._u([{key:"pending",fn:function(){return [_c('b-button',{staticClass:"mr-50",attrs:{"disabled":"","size":"sm","variant":"outline-primary"}},[_c('feather-icon',{attrs:{"icon":"ClockIcon"}})],1)]},proxy:true},{key:"default",fn:function(data){return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:(_vm.getFileType(data)),expression:"getFileType(data)",modifiers:{"hover":true,"bottom":true}}],staticClass:"mr-50",attrs:{"size":"sm","target":"_blank","variant":"outline-primary"},on:{"click":function($event){return _vm.handleViewClick(data);}}},[_vm._v("View")])]}},{key:"rejected",fn:function(){return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:("File not found"),expression:"`File not found`"}]},[_c('b-button',{staticClass:"mr-50",attrs:{"disabled":"","size":"sm","variant":"outline-danger"}},[_c('feather-icon',{attrs:{"icon":"AlertTriangleIcon"}})],1)],1)]},proxy:true}],null,true)}):_vm._e(),_c('b-button',{attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return _vm.deleteFile(props.row.id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1):(props.column.field === 'recommendation')?_c('span',[_c('b-badge',{staticClass:"mb-25",attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")]),_c('br'),_vm._v(_vm._s(props.row.description)+" ")],1):(props.column.field === 'programs_operationstable')?_c('span',[_vm._v(" "+_vm._s(props.row.programs_operationstable.title)+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"column-filter",fn:function(ref){
    var column = ref.column;
    var updateFilters = ref.updateFilters;
return [(column.label === 'Operation')?_c('span',[_c('treeselect',{staticClass:"form-control p-0 border-0",staticStyle:{"border-collapse":"separate","max-width":"240px"},attrs:{"normalizer":_vm.normalizer,"append-to-body":true,"options":_vm.operations,"multiple":true,"value-consists-of":"LEAF_PRIORITY","placeholder":"Select Phase or Activities","default-expand-level":1},on:{"input":function (val) { return updateFilters(column, val); }}})],1):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Showing 1 to ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['10', '20', '30']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"per-page":_vm.pageLength,"total-rows":props.total,"value":1,"first-number":"","last-number":"","next-class":"next-item","prev-class":"prev-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])}),_c('b-modal',{attrs:{"id":"fileViewer"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
    var ok = ref.ok;
    var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"flat-danger "},on:{"click":cancel}},[_vm._v(" Close ")]),_c('b-button',{attrs:{"href":_vm.file_Url,"target":"_blank","variant":"primary"},on:{"click":function($event){return _vm.$bvModal.hide('fileViewer');}}},[_vm._v(" Download ")])]}}])},[(_vm.isPdf(_vm.file_Url))?_c('div',[_c('pdf',{attrs:{"src":_vm.file_Url},on:{"num-pages":function($event){_vm.pageCount = $event},"page-loaded":function($event){_vm.currentPage = $event}}})],1):(_vm.isImage(_vm.file_Url))?_c('div',[_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":_vm.file_Url,"alt":"Preview Image"}})]):_vm._e()]),_c('b-modal',{attrs:{"id":"knowledgeBaseModal","ok-only":"","no-close-on-backdrop":"","ok-title":"Upload","size":"sm","ok-disabled":!_vm.title || !_vm.description || !_vm.selectedOperation || !_vm.recommendationLevel || (_vm.is_internal && !_vm.file) || (!_vm.is_internal && !_vm.file_link),"title":"Add Resource"},on:{"ok":_vm.addFile}},[_c('b-overlay',{attrs:{"show":_vm.$apollo.loading || _vm.mutationLoading}},[_c('validation-observer',{ref:"uploadForm",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Title","label-size":"sm"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Title","required":"","type":"text"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Description","label-size":"sm"}},[_c('b-form-input',{attrs:{"placeholder":"File Description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Operation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Operation","label-size":"sm"}},[_c('treeselect',{class:errors.length > 0 ? 'border-danger rounded' : '',attrs:{"normalizer":_vm.normalizer,"options":_vm.operations,"placeholder":"Select Phase or Activities","default-expand-level":1},model:{value:(_vm.selectedOperation),callback:function ($$v) {_vm.selectedOperation=$$v},expression:"selectedOperation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Recommendation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null,"label":"Recommendation Level","label-size":"sm"}},[_c('v-select',{attrs:{"options":[
                    'Priority',
                    'Interesting',
                    'Additional',
                    'Good-Read' ],"placeholder":"Recommendation Level"},model:{value:(_vm.recommendationLevel),callback:function ($$v) {_vm.recommendationLevel=$$v},expression:"recommendationLevel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',[_vm._v("Upload File?")]),_c('b-form-checkbox',{staticClass:"ml-50",attrs:{"inline":"","switch":""},model:{value:(_vm.is_internal),callback:function ($$v) {_vm.is_internal=$$v},expression:"is_internal"}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"UploadIcon"}})],1),_c('span',{staticClass:"switch-icon-right"},[_c('feather-icon',{attrs:{"icon":"LinkIcon"}})],1)])],1),_c('hr')]),(_vm.is_internal)?_c('b-col',{attrs:{"cols":"12"}},[_c('h6',[_vm._v("Upload File")]),_c('validation-provider',{attrs:{"name":"File","rules":"size:10000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-file',{attrs:{"state":errors.length > 0 ? false : null,"drop-placeholder":"Drop file here...","placeholder":"Choose a file or drop it here..."},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3085802556)})],1):_c('b-col',{attrs:{"cols":"12"}},[_c('h6',[_vm._v("External Link")]),_c('b-form-input',{attrs:{"placeholder":"https://"},model:{value:(_vm.file_link),callback:function ($$v) {_vm.file_link=$$v},expression:"file_link"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }