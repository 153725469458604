<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div
        :class="{'show': isCalendarOverlaySidebarActive}"
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
      >
        <!-- <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" /> -->
        <b-button
          variant="outline-success mx-1 mt-1"
        >
          <feather-icon icon="PlusIcon" />
          Add Slot
        </b-button>
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="{
                ...calendarOptions,
                events: [
                  { title: 'event 1', date: '2023-12-21' },
                  { title: 'event 2', date: '2023-12-22' }
                ]
              }"
              class="full-calendar"
            />
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        :class="{'show': isCalendarOverlaySidebarActive}"
        class="body-content-overlay"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :clear-event-data="clearEventData"
        :event="event"
        @remove-event="removeEvent"
        @add-event="addEvent"
        @update-event="updateEvent"
      />
    </div>
    <b-modal
      id="addSlotModal"
      ok-title="Save"
      no-close-on-backdrop
      size="lg"
      title="Add Slot"
      @ok="addMeeting"
    >
      <b-row>
        <b-col md="12">
          <b-form-group
            class="mb-2"
            label="Select Date"
            label-for="slotDate"
          >
            <flat-pickr
              id="slotDate"
              v-model="slotdate"
              :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
              class="form-control"
              placeholder="Select Date"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="slotdate"
          md="12"
          class="mt-2"
        >
          <h6>Scheduled Slots</h6>
        </b-col>
        <b-col
          v-if="slotdate"
          md="12"
          class="d-flex mb-2"
          style="flex-wrap: wrap;"
        >
          <span
            v-for="(slots, index) in scheduledslots"
            :key="index"
            class="bg-primary text-white d-flex align-items-center"
            style="border-radius: 200px; padding: 2px 8px;"
          >
            {{ slots.users_organizationtable.slots_tables.begindatetimestamp }} - {{ slots.users_organizationtable.slots_tables.enddatetimestamp }}
            <feather-icon
              icon="XCircleIcon"
              style="margin-left:4px; cursor: pointer;"
            />
          </span>
        </b-col>
        <b-col
          v-if="slotdate"
          md="12"
        >
          <b-row>
            <b-col md="11">
              <b-row>
                <b-col
                  md="6"
                >
                  <b-form-group
                    class="mb-2"
                    label="Start time"
                    label-for="StartDateAndTime"
                  >
                    <flat-pickr
                      id="StartDateAndTime"
                      v-model="slotbeginTime"
                      :config="{ enableTime: true, dateFormat: 'h:i K', noCalendar: true}"
                      class="form-control"
                      placeholder="Select start tIme"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                >
                  <b-form-group
                    class="mb-2"
                    label="End time"
                    label-for="EndDateAndTime"
                  >
                    <flat-pickr
                      id="EndDateAndTIme"
                      v-model="slotendTime"
                      :config="{ enableTime: true,dateFormat: 'h:i K', minDate: slotbeginTime, noCalendar: true }"
                      class="form-control"
                      placeholder="Select end tIme"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="1">
              <span
                class="d-flex align-items-center bg-primary text-white justify-content-center"
                style="padding-top: 0.786rem; padding-bottom: 0.786rem; border-radius: 0.358rem; cursor: pointer;"
                @click="addtoselectslot()"
              >
                <feather-icon icon="PlusIcon" />
              </span>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-if="slotdate"
          md="12"
          class="mt-2"
        >
          <h6>Selected Slots</h6>
        </b-col>
        <b-col
          v-if="slotdate"
          md="12"
          class="d-flex mb-2"
          style="flex-wrap: wrap;"
        >
          <span
            v-for="(slots, index) in selectedslots"
            :key="index"
            class="bg-warning text-white d-flex align-items-center mr-1"
            style="border-radius: 200px; padding: 2px 8px;"
          >
            {{ slots.begintime }} - {{ slots.endtime }}
            <feather-icon
              icon="XCircleIcon"
              style="margin-left:4px; cursor: pointer;"
              @click="removeselectedslot(index)"
            />
          </span>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import gql from 'graphql-tag'
import calendarStoreModule from './calendarStoreModule'
// import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './useCalendar'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    // CalendarSidebar,
    CalendarEventHandler,
    flatPickr,
  },
  props: {
    startDate: String,
  },
  data() {
    return {
      slotdate: '',
      slotbeginTime: '',
      slotendTime: '',
      scheduledslots: [],
      selectedslots: [],
    }
  },
  setup(props) {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar(props.startDate)

    fetchEvents()

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    }
  },
  methods: {
    addtoselectslot() {
      if (this.slotbeginTime) {
        if (this.slotendTime) {
          this.selectedslots.push({
            begindatetimestamp: this.slotbeginTime,
            enddatetimestamp: this.slotendTime,
            incubator_id: 20,
          })
          this.slotbeginTime = ''
          this.slotendTime = ''
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Select End Time',
              icon: 'XIcon',
              variant: 'warning',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Select Begin Time',
            icon: 'XIcon',
            variant: 'warning',
          },
        })
      }
    },
    removeselectedslot(index) {
      this.selectedslots.splice(index, 1)
    },
    addSlot() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          insert_slots_table_one(objects: ${this.selectedslots}) {
            id
          }
        }`,
        update: (storeslot, { data: { insert_programs_scheduletable_one } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_programs_scheduletable_one.id ? 'Added successfully' : 'Failed to add',
              icon: insert_programs_scheduletable_one.id ? 'CheckIcon' : 'XIcon',
              variant: insert_programs_scheduletable_one.id ? 'success' : 'warning',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    scheduledslots: {
      query() {
        return gql`
        {
          programs_basicinfo(where: {id: {_eq: ${this.$route.params.id}}}) {
            users_organizationtable {
              id
              slots_tables(where: {begindatetimestamp: {_gte: ${this.slotdate}, _lt: ${this.slotdate + 1}}}) {
                begindatetimestamp
                enddatetimestamp
              }
            }
          }
        }`
      },
      update: data => data.programs_basicinfo,
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
