var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',{staticClass:"repeater-form",style:({height: _vm.trHeight})},[_c('transition-group',{attrs:{"name":"list","mode":"out-in"}},_vm._l((_vm.sections),function(section,s){return _c('b-row',{key:s,staticClass:"mb-2"},[_c('b-col',[_c('b-form-group',{attrs:{"label":("Section " + (s+1)),"label-for":("section-" + (s+1))}},[_c('b-form-input',{attrs:{"id":("section-" + (s+1)),"disabled":_vm.isDisabled,"placeholder":"Section title","required":"","type":"text"},on:{"change":_vm.notifyParent},model:{value:(section.title),callback:function ($$v) {_vm.$set(section, "title", $$v)},expression:"section.title"}})],1)],1),_c('b-col',{attrs:{"cols":"auto"}},[(s === _vm.sections.length - 1 && !_vm.isDisabled)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.sections.push({
              title: '',
              items: [{
                question: '',
                input_type: '',
                is_required: true,
                prevHeight: 0
              }]
            })}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add Section")])],1):_vm._e(),(_vm.sections.length > 1 && !_vm.isDisabled)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-2 mt-2",attrs:{"variant":"danger"},on:{"click":function($event){_vm.sections.splice(s, 1); _vm.notifyParent()}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Remove")])],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"12"}},[_c('transition-group',{attrs:{"tag":"div","name":"list"}},[_vm._l((section.items),function(item,index){return _c('b-row',{key:index,ref:"row",refInFor:true,staticClass:"mx-0 w-100 rounded mb-1 border py-1 pr-1 bg-light",attrs:{"id":"row"+index}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":("Question " + (index+1)),"label-for":("question-" + s + "-" + index)}},[_c('b-form-textarea',{attrs:{"id":("question-" + s + "-" + index),"disabled":_vm.isDisabled,"placeholder":"Question","required":"","rows":"1"},on:{"change":_vm.notifyParent},model:{value:(item.question),callback:function ($$v) {_vm.$set(item, "question", $$v)},expression:"item.question"}}),(item.input_type === 'Dropdown' || item.input_type === 'Multiselect')?_c('b-form-tags',{staticClass:"mt-50",attrs:{"placeholder":"Add Option","disabled":_vm.isDisabled,"input-id":("options-" + s + "-" + index),"tag-pills":item.input_type === 'Dropdown',"remove-on-delete":""},on:{"input":_vm.notifyParent},model:{value:(item.options),callback:function ($$v) {_vm.$set(item, "options", $$v)},expression:"item.options"}}):_vm._e()],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label-for":("data_type-" + s + "-" + index),"label":"Data Type"}},[_c('v-select',{staticClass:"input_type bg-white",attrs:{"id":("data_type-" + s + "-" + index),"disabled":_vm.isDisabled,"options":['Short text',
                               'Long text',
                               'Number',
                               'Dropdown',
                               'Multiselect',
                               'Document' ],"append-to-body":"","label":"Data type","placeholder":"Select from list"},on:{"change":_vm.notifyParent},model:{value:(item.input_type),callback:function ($$v) {_vm.$set(item, "input_type", $$v)},expression:"item.input_type"}}),(item.input_type === 'Document')?_c('small',{staticClass:"d-flex align-items-center pt-25 text-secondary "},[_c('feather-icon',{staticClass:"mx-25",attrs:{"icon":"InfoIcon"}}),_vm._v(" Max file size: 5MB")],1):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-form-group',{attrs:{"label-for":("required-" + s + "-" + index),"label":"Required?"}},[_c('b-form-checkbox',{staticClass:"mt-50",attrs:{"id":("required-" + s + "-" + index),"checked":"true","disabled":_vm.isDisabled,"inline":"","switch":""},model:{value:(item.is_required),callback:function ($$v) {_vm.$set(item, "is_required", $$v)},expression:"item.is_required"}})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"cols":"auto"}},[(!_vm.isDisabled)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"disabled":section.items.length < 2,"variant":"outline-danger"},on:{"click":function($event){section.items.splice(index, 1); _vm.notifyParent()}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1):_vm._e()],1)],1)}),_c('b-row',{key:"button"},[_c('b-col',[(!_vm.isDisabled)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-25",attrs:{"variant":"outline-primary"},on:{"click":function($event){return section.items.push({
                    question: '',
                    options: [],
                    input_type: 'Short text',
                    is_required: true
                  })}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add Question")])],1):_vm._e()],1)],1)],2)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }